.selectWithClear {
  :global(.ant-select-selection__clear) {
    opacity: 1;
  }
  :global(.ant-select-selector) {
    padding-right: 34px !important;
  }
}

.selectWithBubblesPopup {
  :global(.ant-select-item-option-selected:not(.ant-select-item-option-disabled)) {
    background-color: #fafafa;
  }
}

.selectOption {
}

.bubble {
  padding: 6px 10px;
  border-radius: 16px;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
}
