:global([data-tid='sidepanel'].ant-layout-sider) {
  background-color: transparent;
}
:global(.ant-layout-sider .ant-menu) {
  background-color: transparent;
}
:global(.ant-menu-light.ant-menu-root.ant-menu-vertical),
:global(.ant-menu-light.ant-menu-root.ant-menu-inline) {
  border-inline-end: 0 !important;
}

:global([data-tid='sidepanel'] .ant-menu-vertical .ant-menu-item::after) {
  border-right: 0;
}

:global([data-tid='sidepanel'] .ant-menu-item.ant-menu-item-active) {
  background-color: rgba($color: #000000, $alpha: 0.05);
  // background-color: #77c1f66e;
  // color: #0E0E2C;

  &:hover {
    color: #0e0e2c;

    :global(a) {
      color: #0e0e2c;
    }
  }
}

:global([data-tid='sidepanel'] .ant-menu-item a) {
  color: #0e0e2c;

  :global(a) {
    color: #0e0e2c;
  }
}

:global(
    [data-tid='sidepanel'] .ant-menu:not(.ant-menu-horizontal) .ant-menu-item.ant-menu-item-selected
  ) {
  background-color: #3ebfff;
  color: #0e0e2c;
}

.sideMenu {
  height: 100%;
  border-right: 0;
}
