.loginForm {
  max-width: 500px;
}

.loginFormForgot {
  float: right;
}

.loginFormButton {
  width: 100%;
}
