.box {
  background: #f0f0f0;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  line-height: 1.6;

  > * {
    display: block;
  }
}

.headline {
  font-size: 11px;
  margin-bottom: 2px;
}

.totalNumber {
  font-size: 30px;
  line-height: 30px;
}

.trendNumber {
  font-size: 20px;
}

.trendContainer {
  display: flex;
  align-items: center;
  gap: 24px;
}

.trend {
  display: block;
  font-size: 16px;
  border-radius: 13px;
  padding: 0 12px;
}

.trendUp {
  background: #78de86;
}

.trendDown {
  background: #ff8163;
}
