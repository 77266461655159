.item {
  &:hover {
    background-color: #fafafa;
    cursor: pointer;
  }
}

:global(.ant-tag) {
  &.categoryTag {
    cursor: pointer;
    margin-bottom: 4px;

    &:hover {
      cursor: pointer;
      background-color: #ffadd2;
      color: #fff;
    }
  }
}
