@import '../../variables.scss';

.mbxButton {
  color: $color-text;
  font-size: 14px;
  font-style: normal;

  &:global(.ant-btn-lg) {
    font-size: 14px;
  }

  &:global(.ant-btn-dangerous) {
    color: $color-text;
    border-color: $color-danger;

    &:not(:disabled):hover {
      color: $color-text;
      background-color: $color-danger;
      border-color: $color-danger;
    }
  }
}

.mbxButtonPrimary {
  font-weight: 700;
  box-shadow: none;

  &:not(:disabled):hover {
    color: $color-text;
    background-color: darken($color: $color-primary, $amount: 10);
  }
}
.mbxButtonSecondary {
  color: $color-text;
  border-color: rgba($color-text, 0.2);
  // border-color: $color-primary;

  &:not(:disabled):hover,
  &:global(.ant-dropdown-open) {
    color: $color-text;
    background-color: rgba(0, 0, 0, 0.05);
    border-color: $color-text;
  }
}
.mbxButtonTertiary {
  padding: 4px;
  color: $color-text;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: 500;

  :global(> span) {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }

  &:disabled {
    background-color: transparent;
  }

  &:not(:disabled):hover {
    color: $color-text;

    :global(> span) {
      border-bottom: 1px solid $color-primary;
    }
  }
}
