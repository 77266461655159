.uploadListUploadingItems:global(.ant-list) {
  margin: 0 14px;
}

.uploadListDoneItems:global(.ant-list) :global(.ant-list-item),
.uploadListQueuedItems:global(.ant-list) :global(.ant-list-item) {
  padding: 2px 0;
}

.uploadListUploadingItems:global(.ant-list) :global(.ant-list-item-meta-title),
.uploadListQueuedItems:global(.ant-list) :global(.ant-list-item-meta-title),
.uploadListDoneItems:global(.ant-list) :global(.ant-list-item-meta-title) {
  line-height: 1.25;
  margin-top: 4px;
}

.uploadListQueuedCollapse:global(.ant-collapse)
  > :global(.ant-collapse-item > .ant-collapse-header) {
  background-color: #f6f6f6;
  padding: 4px 14px;
}
.uploadListQueuedCollapse:global(.ant-collapse)
  > :global(.ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box) {
  padding: 4px 14px;
}
.uploadListQueuedCollapse:global(.ant-collapse)
  > :global(.ant-collapse-item > .ant-collapse-header .ant-collapse-arrow) {
  margin-right: 8px;
}
