.section {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.box {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1rem;
  margin: 0 7.5px 12px;
}

.boxStatsContainer {
  > * {
    margin-bottom: 12px;
  }
}

.boxLast28Days {
  flex-grow: 1;
}

.boxLast28DaysContainer {
  display: flex;
  gap: 18px;
}

.headline {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 12px;
}

.greyBox {
  border-radius: 4px;
  background-color: #f4f4f4;
  padding: 1rem;
  font-size: 30px;
}

.boxTotalTrendContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
}

.boxTotalTrend {
  display: block;
  font-size: 16px;
  border-radius: 13px;
  padding: 2px 10px;
}

.boxTotalTrendUp {
  background: #78de86;
}

.boxTotalTrendDown {
  background: #ff8163;
}

.separator {
  width: 1px;
  background: #e0e0e0;
  margin: 0 4px;
}

.graphAndTableContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;

  > div {
    min-width: 100%;
    height: 200px;
    order: unset;
  }

  > div:nth-child(3) {
    display: none;
  }

  > table {
    flex-grow: 0;
    height: auto;
  }
}
