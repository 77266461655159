.popover {
  :global {
    .ant-popover-inner-content {
      padding: 2px 10px;
    }
    .ant-select {
      padding-bottom: 6px;
    }
  }
}

:global(.ant-tag).tag {
  cursor: pointer;
  border: 0;

  &:hover {
    cursor: pointer;
    background-color: #ffadd2;
    color: #fff;
  }
}
