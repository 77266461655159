@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/lato-regular.woff2') format('woff2'),
    url('./assets/fonts/lato-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/lato-black.woff2') format('woff2'),
    url('./assets/fonts/lato-black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/lato-blackitalic.woff2') format('woff2'),
    url('./assets/fonts/lato-blackitalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/lato-bold.woff2') format('woff2'),
    url('./assets/fonts/lato-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/lato-bolditalic.woff2') format('woff2'),
    url('./assets/fonts/lato-bolditalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/lato-light.woff2') format('woff2'),
    url('./assets/fonts/lato-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/lato-lightitalic.woff2') format('woff2'),
    url('./assets/fonts/lato-lightitalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

body {
  /* min-width: 480px;
  max-width: 1200px;
  margin: 0 auto; */
  margin: 0;
  padding: 0;
  /* font-family: 'Lato', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

pre {
  margin: 1em 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 11px;
  background: rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.06);
}

h1 {
  font-size: 1.8em;
  font-weight: 600;
}

h2 {
  font-size: 1.6em;
  font-weight: 600;
}

h3 {
  font-size: 1.4em;
  font-weight: 600;
}

h4 {
  font-size: 1.2em;
}

.ant-list-header h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1.2em;
  margin-bottom: 0;
}

.ant-list-item-meta {
  min-width: 0;
}

.ant-list-item-meta-content {
  min-width: 0;
}

.ant-list-item-meta-title {
  text-overflow: ellipsis;
  overflow: hidden;
}

[data-dragdropenabled='true'] * {
  pointer-events: none;
}

.ant-layout {
  background-color: transparent;
}

.ant-select.mbx-round-select .ant-select-selector {
  border-radius: 5px;
}

.ant-form-item-label {
  font-weight: 700;
  /* text-transform: capitalize; */
}

.ant-pagination.ant-pagination-simple .ant-pagination-simple-pager {
  height: 32px;
}

.ant-pagination.ant-pagination-simple .ant-pagination-prev,
.ant-pagination.ant-pagination-simple .ant-pagination-next {
  height: 32px;
  line-height: 32px;
}

.ant-pagination.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 100%;
}

.ant-alert-with-description .ant-alert-message {
  font-weight: 600;
}
