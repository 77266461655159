$bgColor: #0e0e2c;
$lightenBgColor: #3ebfff; // lighten($bgColor, 8);
$lightestBgColor: rgba(#fff, 0.15); //lighten($lightenBgColor, 10);

.switcher:global(.ant-select) {
  display: block;
  // margin-left: -25px;
  width: 240px;
  height: 64px;

  @media (max-width: 767px) {
    width: 220px;
  }

  @media (max-width: 500px) {
    width: 130px;
  }

  // &:global(.ant-select-focused),
  // &:global(.ant-select-open){
  //   background-color: #f00;
  // }

  &:global(.ant-select-disabled) {
    &:not(:global(.ant-select-customize-input)) {
      :global(.ant-select-selector) {
        background-color: $bgColor;
        cursor: pointer;
      }
    }
  }

  &:global(.ant-select-open),
  &:global(.ant-select-focused) {
    &:not(:global(.ant-select-customize-input)) {
      :global(div.ant-select-selector) {
        background-color: $lightestBgColor;
        box-shadow: none;
        color: #0e0e2c;
      }
    }
  }

  :global(.ant-select-arrow) {
    color: white;
  }

  &:not(:global(.ant-select-customize-input)) {
    color: white;
    text-align: left;

    :global(.ant-select-selector) {
      background-color: $bgColor;
      border: 0;
      height: 100%;
      display: flex;
      padding-left: 16px;

      :global(.ant-select-selection-item) {
        color: white;
        align-self: center;
      }
    }
  }
}

.switcherDropdown:global(.ant-select-dropdown) {
  background-color: $bgColor;
  border-radius: 5px;

  :global(.ant-select-item) {
    height: 42px;
    color: white;
    display: flex;
    align-items: center;
    padding-left: 16px;
    margin-top: 6px;
    margin-bottom: 6px;

    span.appName {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    :global(.ant-select-item-option-content) {
      display: flex;
      align-items: center;
    }

    &:global(.ant-select-item-option-active):not(:global(.ant-select-item-option-selected)) {
      background-color: $lightestBgColor;
      &:hover {
        background-color: $lightestBgColor;
      }
    }

    &:global(.ant-select-item-option-selected) {
      background-color: $lightenBgColor;
      // .appName {
      //   color: #0e0e2c;
      //   &:hover {
      //     color: white;
      //   }
      // }
    }
  }
}

.appIcon {
  min-width: 29px;
  text-align: center;
  float: left;
  border-radius: 4px;
  position: relative;
  align-self: baseline;
  overflow: hidden;

  color: white;
  align-items: center;

  span {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
  }
}

.appName {
  margin-left: 8px;
}
