@import '../variables.scss';

.formContainer {
  margin-top: 2em;
  margin-bottom: 2em;
}

:global(.ant-list).list {
  margin-top: 4em;
}

.listHeader {
  display: flex;
  justify-content: space-between;
}

.listItemTitle {
  padding-right: 10px;
}

.listItemTag {
  font-size: 10px;
  line-height: 14px;
  padding-right: 4px;
  padding-left: 4px;
}

.inlineAppDescription {
  font-size: 10px;
  font-style: italic;
}

.listItemDate {
  font-weight: normal;
}

.regionLabel {
  margin-right: 20px;
}

.episodeEditbox {
  padding: 15px 25px;
  background-color: rgb(239 239 239 / 40%);
  border-radius: $border-radius-default;
}

:global(.ant-list-item-meta-avatar) {
  display: flex;
}
