.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 18px;
}

.containerWithMoreThenOneRegion {
  @media (max-width: 1400px) {
    flex-wrap: wrap;
  }
}

.box {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1rem;
  margin: 0 7.5px 12px;
}

.headline {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 12px;
}

.separator {
  @media (max-width: 1400px) {
    display: none;
  }
  width: 1px;
  background: #e0e0e0;
  margin: 0 4px;
}
