.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
}

.headerExtras {
  text-align: right;
  min-width: 274px;

  > * {
    margin-left: 10px;
    margin-bottom: 10px;
  }
}

:global(.ant-page-header-heading .ant-avatar) {
  float: none !important;
  margin-right: 0 !important;
}
