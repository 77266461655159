.graph {
  @media (max-width: 1400px) {
    order: 2;
    height: 300px;
  }
  width: 100px;
  flex-grow: 1;
  display: flex;
  > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  :global(.g2-tooltip-title) {
    font-weight: bold;
  }
}

.break {
  display: none;
  @media (max-width: 1400px) {
    display: block;
    flex-basis: 100%;
    height: 0;
  }
}

.graphHeadline {
  font-size: 11px;
  font-weight: normal;
  margin-bottom: 12px;
}

.table {
  text-align: left;
  border-collapse: collapse;
  height: 100%;

  @media (max-width: 1400px) {
    flex-grow: 1;
  }

  td:not(:first-child):not(:last-child),
  th:not(:first-child):not(:last-child) {
    padding: 8px 16px;
  }

  thead tr,
  tbody tr:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
  }

  th {
    font-size: 11px;
    font-weight: normal;
    color: grey;
  }

  th:nth-last-child(2) {
    font-weight: 600;
    color: black;
  }

  td {
    font-size: 13px;
  }

  td:nth-last-child(-n + 2) {
    font-weight: 600;
  }

  th:not(:first-child),
  td:not(:first-child) {
    text-align: right;
  }
}

.localeName {
  max-width: 100px;
  min-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trendUp {
  color: #01912d;
}

.trendDown {
  color: #cb390b;
}

.trendNA {
  color: darkgrey;
}
