@import '../variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
}

.listItem {
  &:hover {
    background-color: #fafafa;
    cursor: pointer;
  }
}

.selectWithClear {
  :global(.ant-select-selection__clear) {
    opacity: 1;
  }
  :global(.ant-select-selector) {
    padding-right: 34px !important;
  }
}

:global(.ant-tag).episodeTag {
  cursor: pointer;
  border: 0;

  &:hover {
    cursor: pointer;
    background-color: #adc6ff;
    color: #fff;
  }
}

.filterBar {
  margin-top: 2em;
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.calendarOverlayContainer {
  background-color: white;
  max-width: 550px;
  padding: 0.5rem;
  box-shadow: 0px 4px 23px -12px rgba(66, 68, 90, 0.8);
}

.episodeInfobox {
  display: flex;
  padding: 12px 25px;
  background-color: rgb(239 239 239 / 40%);
  border-radius: $border-radius-default;
}

.analyticsLabel {
  color: #aeaeae;
  padding-right: 4px;
}

.analyticsValue {
  padding-right: 6px;
}

.seperatorLine {
  margin-right: 10px;
  border-right: 1px solid #aeaeae;
  padding-right: 8px;
}

.analyticsLink {
  color: #0e0e2c;
  text-decoration: underline;
  font-size: 12px;
  padding-left: 4px;
  line-height: 15px;
}
