.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
}

.listItem {
  &:hover {
    background-color: #fafafa;
    cursor: pointer;
  }
}
