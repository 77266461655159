.rolesContainer {
  display: block;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.rolesItem {
  padding: 16px;
  border-bottom: 1px solid #d9d9d9;

  &:last-child {
    border-bottom: none;
  }
}
