.rankingContainer {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 12px;
}

.weekInput {
  :global(.ant-picker-input > input) {
    font-size: 12px;
  }
}
