.form {
}

.loginFormForgot {
  float: right;
}

.loginFormButton {
  width: 100%;
}
