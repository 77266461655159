.table {
  width: 100%;
  font-size: 13px;

  td {
    padding-bottom: 4px;
  }

  td:nth-child(1) {
    width: 10px;
  }

  td:nth-child(2) {
    font-weight: bold;
  }

  td:nth-child(3),
  td:nth-child(4) {
    text-align: right;
  }
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
