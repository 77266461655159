.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
}

.item {
  &:hover {
    background-color: #fafafa;
    cursor: pointer;
  }
}

.tag {
  font-size: 10px;
  line-height: 14px;
  padding-right: 4px;
  padding-left: 4px;
}
