.container {
  padding: 12px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  row-gap: 9px;
  column-gap: 18px;
}

.containerWithMoreThenOneLanguage {
  @media (max-width: 1400px) {
    flex-wrap: wrap;
  }
}

.separator {
  @media (max-width: 1400px) {
    display: none;
  }
  width: 1px;
  background: #e0e0e0;
  margin: 0 4px;
}
