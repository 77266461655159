.logo {
  width: 152px;
  height: 27px;
  margin: 18px 24px 16px 16px;
  display: inline-block;
  line-height: 1;
}

.logoImg {
  width: 152px;
}

.topMenu {
  line-height: 64px;
}

.header {
  padding: 0;
  background: #0e0e2c;
}

.headerCentered {
  max-width: 1400px;
  margin: 0 auto;
}

.headerContainer {
  display: flex;
  justify-content: space-between;

  .left {
    display: flex;
  }

  .right {
    display: flex;
    div > * {
      margin: 0 12px;
    }
  }
}

@media (max-width: 767px) {
  .logoWrapper {
    overflow: hidden;
    width: 58px;
    margin-right: 16px;
  }
}

@media (max-width: 500px) {
  .logoWrapper {
    display: none;
  }
}
