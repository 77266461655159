.container {
  margin: 2em 0;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5em;
  padding-bottom: 1em;
}

.item {
  &:hover {
    background-color: #fafafa;
    cursor: pointer;
  }
}
