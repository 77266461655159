.listItem {
  :global(.ant-list-item-meta-description) {
    font-size: 12px;
  }
}

.itemDate {
  padding-left: 8px;
  color: #00000073;
  font-size: 11px;
}
