.draggingOkay {
  border-color: rgb(0, 97, 0) !important;
  background-color: rgb(198, 239, 206) !important;
  color: rgb(0, 97, 0) !important;
}

.draggingNotOkay {
  border-color: rgb(156, 0, 6) !important;
  background-color: rgb(255, 199, 206) !important;
  color: rgb(156, 0, 6) !important;
}
