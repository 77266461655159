.thumbnailContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:global([data-isdefault='true']) {
    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 8px;
      background-color: #3ebfff50;
      top: 0;
      left: -14px;
      border-radius: 3px;
    }
  }
}

.timeLabel {
  position: absolute;
  display: inline-block;
  bottom: 2px;
  left: 2px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 10px;
  line-height: 13px;
  font-weight: 600;
  padding: 0 1px;

  user-select: none;
}
