.selectItem {
  .bubble {
    background-color: #efefef;
    width: 55px;
    padding: 2px 4px;
    border-radius: 16px;
    margin-right: 6px;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
  }
}

:global(.ant-select-selection-item) {
  .selectItem {
    .bubble {
      line-height: calc(22 / 14); //1.5714285714285714;
    }
  }
}
