.versionTable {
  border: 1px solid #acacac;
  margin: 1em 0 2em 0;
  border-radius: 2px;
}

.versionTableWrapper {
  position: relative;
  overflow-x: scroll;
}

.versionTableTitle {
  padding: 6px 2px;
  font-weight: bold;
  background-color: #efefef;
}

.sticky {
  position: sticky;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  left: 0px;

  background-color: #fff;
}

.zipname {
  padding: 0 0 0 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 11px;
  font-family: 'Courier New', Courier, monospace;
  // font-weight: bold;
}

.versionRow {
  height: 24px;
  border-top: 1px solid #efefef;
}

.versionLabel {
  padding: 0 0 0 2px;
  width: 150px;
  background-color: #acacac;
}

.versionTitleCell {
  padding: 0 0 0 2px;
  width: 50px;
  background-color: #acacac;
  border-left: 1px solid #efefef;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.versionCell {
  border-left: 1px solid #efefef;
}
