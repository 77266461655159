:global(.ant-list).list {
  margin-top: 2em;
  margin-bottom: 2em;
}

.listItem {
  &:hover {
    background-color: #fafafa;
    cursor: pointer;
  }
}
