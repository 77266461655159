.mbxTag {
  cursor: pointer;
  border: 0;

  &:hover {
    cursor: pointer;
    background-color: #adc6ff;
    color: #fff;
  }

  &:global(.large) {
    font-size: 16px;
    padding: 4px 8px;
  }
}
