.menu {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.name {
  color: #ddd;
  margin-right: 1em;
}

.adminSettingsIcon {
  color: white;
  font-size: 16px;
  margin-left: 8px;
  cursor: pointer;
  margin-right: -10px;
}

@media (max-width: 767px) {
  .adminSettingsText {
    display: none;
  }

  .adminSettingsIcon {
    font-size: 32px;
    position: relative;
    top: 7px;
    margin-left: 0px;
  }
}
